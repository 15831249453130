import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import workloadReducer from './workloadReducer';
import userReducer from './userReducer';
const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_TRANSFORM,
  onError: function (error: any) {
    console.log("Error for redux encrypt", error);
  }
});
const persistConfig = {
  key: 'persisted',
  storage,
  transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, combineReducers({
  workloadReducer: workloadReducer,
  userReducer: userReducer
}));

export default persistedReducer;
