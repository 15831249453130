import { FILE_PREVIEW } from './actionType';

const file_preview_open = (data: any) => {    
    return {
        type: FILE_PREVIEW,
        payload: data
    }
}

export {
    file_preview_open
};
