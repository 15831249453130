import { createContext, FC, ReactNode, useCallback, useState, useEffect, useRef, useMemo } from "react";
import { SnackContext } from './interface'
import SnackbarComponent from "./SnackbarComponent/SnackbarComponent";
export const SnackbarContext = createContext<SnackContext>({} as SnackContext);

export const SnackbarProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [snackbarKey, setSnackbarKey]=useState(0);

  const timeoutRef = useRef<number | undefined>(undefined); 

  const snackbarSetting = useCallback((data: boolean, value: string, subtype: string) => {
    setOpen(data);
    setMessage(value);
    setType(subtype);
    setSnackbarKey(prevKey => prevKey + 1);
  }, [setOpen, setMessage, setType, setSnackbarKey]);

  const handleClose = () => {
    setOpen(false);
  }
  useEffect(() => {
     if(open){
      if(timeoutRef.current){
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        setOpen(false);
      }, 3000);
    } return () => { clearTimeout(timeoutRef.current); } },[open,snackbarKey]);

    const value = useMemo(() => ({ open, snackbarSetting }),[]);
    
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <SnackbarComponent key={snackbarKey} open={open} message={message} type={type} handleClose={handleClose}/>
    </SnackbarContext.Provider>
  );
};
