import { PROVIDER, SCHEDULEID, WORKLOADDATE } from "../../actions/actionType"

const initialState = {
    provider: null,
    scheduleId: null,
    workloadDate: null
}

export default function workloadReducer(state = initialState, action: any) {
    switch (action.type) {
        case PROVIDER: {
            return {
                ...state,
                provider: action.payload
            }
        }
        case SCHEDULEID: {
            return {
                ...state,
                scheduleId: action.payload
            }
        }
        case WORKLOADDATE: {
            return {
                ...state,
                workloadDate: action.payload
            }
        }
        default:
            return state
    }
}