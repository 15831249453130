import { USER, USERSETTING, USERTOKEN, USERRETOKEN, PROFILE } from "./actionType"

const userAction = (data: any) => ({ type: USER, payload: data });
const userSettingAction = (data: any) => ({ type: USERSETTING, payload: data });
const userTokenAction = (data: any) => ({ type: USERTOKEN, payload: data });
const userReTokenAction = (data: any) => ({ type: USERRETOKEN, payload: data });
const userProfileAction = (data: any) => ({ type: PROFILE, payload: data });

export {
    userAction,
    userSettingAction,
    userTokenAction,
    userReTokenAction,
    userProfileAction
}