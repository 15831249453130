import { Button } from '@mui/material';
import styles from './FallBackRender.module.css';

function FallbackRender() {

  return (
    <div role="alert" className={styles.root} style={{height:window.innerHeight}}>
      <span style={{color:'grey'}}>Oops! Something went wrong</span>
      <span>Try refreshing the page, or try again later.</span>
      <Button onClick={() => window.location.reload()} color="info" variant='contained' className={styles.try_again}>
        {"Refresh Page"}
      </Button>
    </div>
  );
}
export default FallbackRender;