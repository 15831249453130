import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import buttleStyles from '../../Styles/component/ButtonStyle.module.css';
import { SocketContext } from '../Sockets/Socket';
import typoStyles from '../../Styles/component/Typography.module.css';
import { anotherSession, idleTimeMsg, inactivityMsgPrimary, inactivityMsgSecondary, sessionmsg } from '../../Services/utils/constant';
import CustompasswordTextFields from '../CustomPasswordTextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { reloginAPI } from '../../Services/api/authMonitor';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';
import instance from '../../axios';
import { GlobalAlertGFContext } from './GlobalAlertProvider';
import { userAction, userProfileAction, userReTokenAction, userSettingAction, userTokenAction } from '../../Services/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { sessionLogout } from '../../Services/api/userAPIService';

interface GlobalAlertProps {
    open: boolean,
    label?: any,
    handleClose: any
}

export default function GlobalAlert({ open, label, handleClose }: GlobalAlertProps) {
    const dispatch = useDispatch();
    const { socket } = React.useContext(SocketContext);
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { alertSetting } = React.useContext(GlobalAlertGFContext)
    const user = useSelector((state: any) => state.persistReducers?.userReducer?.user);
    const refreshToken = useSelector((state: any) => state.persistReducers?.userReducer?.refreshToken);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);

    const handleCloseLogout = async () => {
        const session_result = await sessionLogout({id: user?.id, token: userToken, refreshToken: refreshToken});
        if(session_result?.status){
            localStorage.clear();
            sessionStorage.clear();
            socket.disconnect()
            window.location.href = `/?message=${encodeURIComponent(session_result?.message)}`;
        }else{
            snackbarSetting(true, session_result?.message, 'error')
        }
    }

    const loginSchema = Yup.object().shape({
        password: Yup.string()
            .required('*Required')
            .trim()
            .min(8, "Must be 8 characters or more")
            .matches(/[a-z]+/, "One lowercase character")
            .matches(/[A-Z]+/, "One uppercase character")
            .matches(/[@$!%*#?&]+/, "One special character")
            .matches(/\d+/, "One number"),
        userName: Yup.string().required('*Required').trim()
    });
    const userObj = {
        userName: user?.userName,
        password: "",
    }
    const submitReloginForm = async (data: any) => {
        data.refresh = refreshToken;
        data.role = user?.userRole;
        const res: any = await reloginAPI(data);
        if (res.status && res.statusCode === 200) {
            handleClose();
            dispatch(userAction(res?.user));
            dispatch(userSettingAction(res?.userSetting));
            dispatch(userTokenAction(res?.userToken));
            dispatch(userReTokenAction(res?.refreshToken));
            dispatch(userProfileAction(JSON.stringify(res?.user.profile)));
            instance.defaults.headers.common['Authorization'] = `Bearer ${res?.userToken}`;
        } else {
            if (res.statusCode === 403) {
                alertSetting(true, 2)
            } else {
                snackbarSetting(true, res?.message, 'error')
            }
        }
    }
    return (
        <Dialog style={{ zIndex: 2000 }} open={open} maxWidth={"xs"} fullWidth>
            <DialogContent>
                {label === 1 && <DialogContentText sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <span className={typoStyles.typoRobotoMediumprimary262229}>{inactivityMsgPrimary}</span>
                    <span className={typoStyles.typoRobotoRegoularprimary31621l}>{inactivityMsgSecondary + ` ${user?.userName}`}</span>
                    <Formik
                        initialValues={{
                            ...userObj,
                        }}
                        enableReinitialize
                        validationSchema={loginSchema}
                        onSubmit={(values: any) => submitReloginForm(values)}
                        validateOnMount
                    >
                        {formik => {
                            return (

                                <Form>
                                    <div>
                                        <CustompasswordTextFields placeholder="Password" name="password" />
                                    </div>
                                    <DialogActions sx={{ marginTop: "20px" }}>
                                        <Button className={buttleStyles.cancelbutton} variant="contained" onClick={handleCloseLogout}>
                                            <span className={typoStyles.typoRobotoRegoularprimary5914}>
                                                {'Log out'}
                                            </span>
                                        </Button>
                                        <Button
                                            disabled={formik.values.password.trim() === ''}
                                            className={buttleStyles.yesbutton} type='submit' variant="contained" >
                                            {"Continue"}
                                        </Button>

                                    </DialogActions>
                                </Form>
                            )
                        }
                        }
                    </Formik>
                </DialogContentText>}
                {label === 0 && <DialogContentText sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <span className={typoStyles.typoRobotoMediumprimary262229}>{idleTimeMsg}</span>
                </DialogContentText>}
                {label === 2 && <DialogContentText sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <span className={typoStyles.typoRobotoMediumprimary262229}>{sessionmsg}</span>
                </DialogContentText>}
                {label === 3 && <DialogContentText sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <span className={typoStyles.typoRobotoMediumprimary262229}>{anotherSession}</span>
                </DialogContentText>}
            </DialogContent>
            {label !== 1 && <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button className={buttleStyles.yesbutton} variant="contained" onClick={handleCloseLogout}>
                    OK
                </Button>
            </DialogActions>}
            <div style={{ height: "15px" }}></div>
        </Dialog>
    );
}