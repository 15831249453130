import { APPOINTMENT_DATA, AUDIO_MODAL } from "./actionType"

const audioPlayer_action = (data: any) => {
    return {
        type: APPOINTMENT_DATA,
        payload: data
    }
}
const audioModal_action = (data: any) => {    
    return {
        type: AUDIO_MODAL,
        payload: data
    }
}
export {
    audioPlayer_action,
    audioModal_action
}