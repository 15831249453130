import { combineReducers } from 'redux'

import authMonitorReducer from './authMonitorReducer';
import notificationCountReducer from './notificationCountReducer';
import audioPlayerReducer from './audioPlayerReducer';
const nonPersistReducers = combineReducers({
    authMonitor: authMonitorReducer,
    notificationCountReducer: notificationCountReducer,
    audioPlayerReducer: audioPlayerReducer,
})

export default nonPersistReducers;