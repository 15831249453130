import { Grid } from "@mui/material";
import React from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { SnackbarContext } from "../../Components/Snackbar/SnackbarProvider";

export const LaunchLayout = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const { snackbarSetting } = React.useContext(SnackbarContext)

  React.useEffect(() => {
      const params = new URLSearchParams(location.search);
      const message = params.get('message');
      if (message) {
          snackbarSetting(true, decodeURIComponent(message), 'success');
      }
  }, [location.search, snackbarSetting]);
  
  return (
    <Grid container spacing={0}>
        <Grid item xs={12}>
            {outlet}
        </Grid>
    </Grid>
  );
};
