import React from 'react';
import { ReactComponent as Taskselected } from '../Assests/Icons/Task selected.svg'
import { ReactComponent as Setting } from '../Assests/Icons/Settings.svg'
import { ReactComponent as Auditor } from '../Assests/Icons/Auditor.svg';
const WorkloadManager = React.lazy(() => import('./WorkloadManager/WorkloadManager'));
const Workload = React.lazy(() => import('./Workload/Workload'));
const Auditors = React.lazy(() => import('./Auditors/Auditors'));
const Settings = React.lazy(() => import('./Settings/Settings'));
const TaskManager = React.lazy(() => import('./TaskManager/TaskManager'));
const AuditorsManager = React.lazy(() => import('./AuditorsManager/AuditorsManager'));

export const routerArr = [{
    id: 1,
    path: "/taskmanager",
    label: 'Task Manager',
    component: <TaskManager />,
    icon: <Taskselected />,
    roles: ['auditoradmin', 'auditormanager', 'auditor'],
    permission: ['auditormanager', 'auditoradmin', 'auditor']
},
{
    id: 2,
    path: "/workload",
    label: 'Workload',
    component: <WorkloadManager />,
    icon: <Taskselected />,
    roles: ['auditoradmin', 'auditormanager', 'auditor'],
    permission: ['auditormanager', 'auditoradmin', 'auditor']
},
{
    id: 3,
    path: "/workload-detail",
    label: 'Workload Detail',
    component: <Workload />,
    icon: <Taskselected />,
    roles: ['auditoradmin', 'auditormanager', 'auditor'],
    permission: ['auditormanager', 'auditoradmin', 'auditor']
},
{
    id: 4,
    path: "/auditors",
    label: 'Auditors',
    component: <Auditors />,
    icon: <Auditor />,
    roles: ['auditoradmin', 'auditormanager', 'auditor'],
    permission: ['auditormanager', 'auditoradmin']
},
{
    id: 5,
    path: "/auditorsManager",
    label: 'Auditor Manager',
    component: <AuditorsManager />,
    icon: <Auditor />,
    roles: ['auditoradmin', 'auditormanager', 'auditor'],
    permission: ['auditoradmin']
},
{
    id: 6,
    path: "/settings",
    label: 'Settings',
    component: <Settings />,
    icon: <Setting />,
    roles: ['auditoradmin', 'auditormanager', 'auditor'],
    permission: ['auditormanager', 'auditoradmin', 'auditor']
}
];