import React from 'react'
import { useField } from 'formik'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { TextField } from '@mui/material';
type TextFieldProps = {
  name: string
  placeholder: string
  onChange?: (e: any) => void
}
const CustompasswordTextFields = ({
  name,
  placeholder,
  ...otherProps
}: TextFieldProps) => {
  const [field, meta] = useField(name)

  const configTextField = {
    helperText: '',
    error: false,
    ...field,
    ...otherProps,
  }
  const error = meta && meta.touched && meta.error ? true : false
  if (meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }
  const [values, setValues] = React.useState({
    showPassword: false,
  });


  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  return (
    <TextField
      placeholder={placeholder}
      variant="outlined"
      sx={{
        input: {
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#363636',
            opacity: 1,
          },
          '&::-ms-reveal': {
            display: "none"
          },
          '&::-ms-clear': {
            display: "none"
          },
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset"
          }
        },
        marginTop: '20px',
        width: '404px',
        height: '52px',
      }}
      type={values.showPassword ? 'text' : 'password'}

      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...configTextField}
      error={error}
    />
  )
}
export default CustompasswordTextFields
