import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

type PrivateRouteProps = {
    children: React.ReactNode,
    permission: string[]
}
export const PrivateRoute = ({ children, permission }: PrivateRouteProps) => {
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const user = useSelector((state: any) => state.persistReducers?.userReducer?.user);

    if (!userToken || !user?.userRole || !permission?.some(item => user?.userRole === item)) {        
        return <Navigate to={'/'} />;
    }
    return (<>
        {children}
    </>)
};