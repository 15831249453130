import React, { FC } from 'react';
import styles from './DraggableComponent.module.css';
import Draggable from 'react-draggable';
import { CircularProgress, IconButton, Slider, Tooltip } from '@mui/material';
import typoStyles from '../../Styles/component/Typography.module.css'
import { ReactComponent as CrossIcon } from '../../Assests/Icons/Cross icon.svg';
import { ReactComponent as PINIcon } from '../../Assests/Icons/Group 19283.svg';
import { ReactComponent as PINIcon1 } from '../../Assests/Icons/Collapse.svg';
import { ReactComponent as ForwardIcon } from '../../Assests/Icons/Group 19264.svg';
import { ReactComponent as BackwardIcon } from '../../Assests/Icons/Group 19263.svg';
import { ReactComponent as PlayIcon } from '../../Assests/Icons/Group 16724.svg';
import { ReactComponent as PauseIconParent } from '../../Assests/Icons/Group 19285.svg';
import { ReactComponent as PauseIconChild } from '../../Assests/Icons/Group 19284.svg';
import AudioList from '../../Pages/TaskManager/Component/AudioDialog/AudioList/AudioList';
import { useAudiopopupService } from '../../Services/audioPopupService/audioPopupService';
import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel';
import { ReactComponent as Forward10Icon } from '../../Assests/Icons/Path 11778.svg'
import { ReactComponent as Replay10Icon } from '../../Assests/Icons/Path 11779.svg'
import { ReactComponent as FastForward10Icon } from '../../Assests/Icons/Group 19270.svg'
import { ReactComponent as FastReplay10Icon } from '../../Assests/Icons/Group 19271.svg'
interface DraggableComponentProps {
    children?: any
}

const DraggableComponent: FC<DraggableComponentProps> = () => {
    const [pinPlayerOpen, setPinPlayerOpen] = React.useState(false);
    const { songVlu, dragHandler, getTime, fastBackward, playSongHandler, fastForward, timeUpdateHandler, audioRef, songInfo, isPlaying, fileurl, files, selectedFile, selectSong, handleClose, loader, setLoader, downProgress, listRootref, onScroll,  FastForwardPlay, FastBackwardPlay } = useAudiopopupService();

    const pinPlayer = (flag: any) => {
        setPinPlayerOpen(flag);
    }

    const loadedfile = () => {
        setLoader(true);
    }

    const loderComponent = (value: any) => {
        if (value > 0) {
            return <CircularProgressWithLabel value={value} />;
        }
        return <CircularProgress />;
    }
    // const handleAudioLoad = () => {
    //     if (fileurl) {
    //         URL.revokeObjectURL(fileurl);
    //       }
    // }
    return (<div>
        {pinPlayerOpen ?
            <Draggable>
                <div className={styles.draggable_Player_root}>
                    <div >
                        <div className={styles.audio_header}>
                            <IconButton onClick={() => pinPlayer(false)}>
                                <PINIcon />
                            </IconButton>
                            <div style={{ overflow:"auto"}} className={styles.name_root}>
                                <span className={typoStyles.typoRobotoRegulargreyprimary551621Overflow}>
                                    {selectedFile?.filePathKey?.split('/') ? selectedFile?.filePathKey?.split('/')[selectedFile?.filePathKey?.split('/').length - 1] : ''}
                                </span>
                            </div>
                            <IconButton onClick={handleClose}>
                                <CrossIcon />
                            </IconButton>
                        </div>
                        <div className={styles.audio_root}>
                            <Slider
                                min={0}
                                max={songInfo.duration || 0.0}
                                value={songVlu}
                                onChange={dragHandler}
                                aria-label="Song"
                                step={0.01}
                                sx={{
                                    color: '#D3D3D3',
                                    '& .MuiSlider-track': {
                                        border: 'none',
                                        background: "#427BE0"
                                    },
                                    '& .MuiSlider-thumb': {
                                        width: 18,
                                        height: 18,
                                        background: '#427BE0',
                                        border: "3px solid #FFFFFF",
                                        opacity: 1,
                                        willChange: "transform"
                                    },
                                }}
                            />
                            <div className={styles.sliderTimer}>
                                <span style={{ color: "white" }} className={[`${styles.curTime} ${typoStyles.typoRobotoRegularprimary121417}`].join(" ")}>
                                    {getTime(songInfo.currentTime)}
                                </span>
                                <span style={{ color: "white" }} className={[`${styles.durTime} ${typoStyles.typoRobotoRegularprimary121417}`].join(" ")}>
                                    {getTime(songInfo.duration || 0.0)}
                                </span>
                            </div>
                            <div className={styles.control_btn_root}>
                                <Tooltip title={"Backward"}>
                                    <IconButton onClick={fastBackward} disabled={(Math.floor(songInfo.duration) <= 10 || Math.floor(songInfo.currentTime) === 0) ? true : false}>
                                        <ForwardIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton onClick={playSongHandler} >
                                {!isPlaying ? (
                                        <PlayIcon />
                                    ) : (
                                        <PauseIconChild />
                                    )}
                                </IconButton>
                                <Tooltip title={"Forward"}>
                                    <IconButton onClick={fastForward} disabled={Math.floor(songInfo.duration) <= 10}>
                                        <BackwardIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
            :
            <div className={styles.draggable_parent_player_root}>
                <AudioList
                    songs={files}
                    selectedFile={selectedFile}
                    selectSong={selectSong}
                    listRootref={listRootref}
                    onScroll={onScroll}
                />
                <div className={styles.dialogaudioplayer}>
                    <div className={styles.audio_header}>
                        <IconButton onClick={() => pinPlayer(true)}>
                            <PINIcon1 />
                        </IconButton>
                        <IconButton onClick={handleClose}>
                            <CrossIcon />
                        </IconButton>
                    </div>
                    <div className={styles.playerRoot}>
                        <span className={typoStyles.typoRobotoBoldprimary218}>{"Audio"}</span>
                        <span className={typoStyles.typoRobotoRegoularprimary2621lh}>
                            {selectedFile?.filePathKey?.split('/') ? selectedFile?.filePathKey?.split('/')[selectedFile?.filePathKey?.split('/').length - 1] : ''}
                        </span>
                        {loader ? <div className={styles.playerComponent}>
                            <div className={styles.slideComponent}>
                                <Slider
                                    min={0}
                                    max={songInfo.duration || 0.0}
                                    value={songVlu}
                                    onChange={dragHandler}
                                    aria-label="Song"
                                    step={0.01}
                                    sx={{
                                        width: 312,
                                        paddingTop: "10px",
                                        color: '#D3D3D3',
                                        '& .MuiSlider-track': {
                                            border: 'none',
                                            background: "#427BE0"
                                        },
                                        '& .MuiSlider-thumb': {
                                            width: 24,
                                            height: 24,
                                            background: '#427BE0',
                                            border: "3px solid #FFFFFF",
                                            opacity: 1,
                                            willChange: "transform"
                                        },
                                    }}
                                />
                                <div className={styles.sliderTimer}>
                                    <span className={[`${styles.curTime} ${typoStyles.typoRobotoRegularprimary21624}`].join(" ")}>
                                        {getTime(songInfo.currentTime)}
                                    </span>
                                    <span className={[`${styles.durTime} ${typoStyles.typoRobotoRegularprimary21624}`].join(" ")}>
                                        {getTime(songInfo.duration || 0.0)}
                                    </span>
                                </div>
                            </div>
                            <div className={styles.sameLine}>
                                <IconButton onClick={FastBackwardPlay} disabled={(Math.floor(songInfo.duration) <= 20 || Math.floor(songInfo.currentTime) === 0) ? true : false} className={styles.icon}>
                                    <FastReplay10Icon
                                        className={styles.icon}
                                        color="disabled"
                                    />
                                </IconButton>
                                <IconButton onClick={fastBackward} disabled={(Math.floor(songInfo.duration) <= 10 || Math.floor(songInfo.currentTime) === 0) ? true : false} className={styles.icon}>
                                    <Replay10Icon
                                        className={styles.icon}
                                        color="disabled"
                                    />
                                </IconButton>
                                <IconButton onClick={playSongHandler} >
                                    {!isPlaying ? (
                                        <PlayIcon style={{width:45, height: 45}}/>
                                    ) : (
                                        <PauseIconParent/>
                                    )}
                                </IconButton>
                                <IconButton onClick={fastForward} disabled={Math.floor(songInfo.duration) <= 10} className={styles.icon}>
                                    <Forward10Icon
                                        className={styles.icon}
                                        color="disabled"
                                    />
                                </IconButton>
                                <IconButton onClick={FastForwardPlay} disabled={Math.floor(songInfo.duration) <= 20} className={styles.icon}>
                                    <FastForward10Icon
                                        className={styles.icon}
                                        color="disabled"
                                    />
                                </IconButton>
                            </div>
                        </div> : loderComponent(downProgress)}

                    </div>
                </div>
            </div>}
        <audio
            ref={audioRef}
            onCanPlay={() => loadedfile()}
            crossOrigin='anonymous'
            onTimeUpdate={timeUpdateHandler}
            onLoadedMetadata={timeUpdateHandler}
            // onLoadedData={handleAudioLoad}
            src={fileurl}
        />
    </div>
    )
};

export default DraggableComponent;
