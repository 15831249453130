import API from "./index";

export const getNotification = async (paramsObj: any) => {
    try {
        const res = await API.get(`notification/${paramsObj.pageSize}/${paramsObj.pageNumber}`);        
        if (res) return res;
    } catch (error) {
        return error;
    }
}
export const getNotificationCount = () => API.handleApiRequest(() => API.get(`notification/count`));        
export const markAllasRead = () => API.handleApiRequest(() => API.get(`notification/markallread`));
export const markasRead = (data: any) => API.handleApiRequest(() => API.post(`notification/markread`,data));