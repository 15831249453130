import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import NotFound from './Components/NotFound'
import Mainlayout from './Pages/Layout/MainLayout/Mainlayout'
import SignIn from './Pages/User/SignIn/signIn.lazy'
import ForgotPasswordScreen from './Pages/User/ForgotPassword/ForgotPassword.lazy'
import { LaunchLayout } from './Pages/Layout/LaunchLayout'
import { PrivateRoute } from './Pages/Layout/PrivateRoute'
import { Suspense, useContext, useEffect } from 'react';
import { SocketContext } from './Components/Sockets/Socket';
import { routerArr } from './Pages/Routes';
import BackdropLoader from './Components/BackdropLoader/BackdropLoader';
import { ErrorBoundary } from "react-error-boundary";
import FallbackRender from './Components/FallbackRender/FallbackRender';
import instance from './axios';
import { useSelector } from 'react-redux';

instance.interceptors.request.use(request => {
    return request;
}, error => Promise.reject(error));

function App() {
    const { socket } = useContext(SocketContext);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const userId = useSelector((state: any) => state.persistReducers?.userReducer?.user?.id);

    if (userToken) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
    }

    useEffect(() => {
        if (userId) {
            socket?.emit('join_room', { userId });
        }
    }, [socket]);

    return (<>
        <Router>
            <Routes>
                <Route element={<Mainlayout />}>
                    {routerArr?.map((item) =>
                        <Route
                            key={item.id}
                            path={item.path}
                            element={
                                <ErrorBoundary fallbackRender={FallbackRender} onReset={() => window.location.href = '/'}>
                                    <Suspense fallback={<BackdropLoader />}>
                                        <PrivateRoute permission={item.roles}>
                                            {item.component}
                                        </PrivateRoute>
                                    </Suspense>
                                </ErrorBoundary>
                            }
                        />
                    )}
                </Route>
                <Route element={<LaunchLayout />}>
                    <Route path="/" element={<ErrorBoundary fallbackRender={FallbackRender} onReset={() => window.location.href = '/'}>
                        <Suspense fallback={<BackdropLoader />}><SignIn /></Suspense></ErrorBoundary>} />
                    <Route path="/forget" element={<ErrorBoundary fallbackRender={FallbackRender} onReset={() => window.location.href = '/'}>
                        <Suspense fallback={<BackdropLoader />}><ForgotPasswordScreen /></Suspense></ErrorBoundary>} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    </>
    )
}
export default App;


