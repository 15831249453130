export const statusLabel = (tag: string) => {
    return statusTagMenuItem?.find((item: any) => item.key === tag)?.label;
}
export const idleTimeMsg = "Your session has expired due to inactivity";
export const inactivityMsgPrimary = "Your session has timed out due to inactivity!";
export const inactivityMsgSecondary = "Please enter your password for"
export const sessionmsg = "Session expired!";
export const anotherSession = "Your session has expired due to another login";

export const statusTagMenuItem = [
    {
        id: 1,
        label: 'To Do',
        key:'to do',
        divPrimary: 'primarytodo',
        typography: 'typoRobotoRegoularprimary3514',
        divsubSecondary: '#D9F5FD',
        divsScondary: 'todo',
        color: "#0059FF",
        roles:['auditor', 'auditoradmin', 'auditormanager']
    },
    {
        id: 2,
        label: 'In Progress',
        key:'in progress',
        divPrimary: 'primaryinprogress',
        divsScondary: 'inprogress',
        divsubSecondary: '#FEEFCB',
        typography: 'typoRobotoRegoularprimary3714',
        color: "#FFA600",
        roles:['auditor', 'auditoradmin', 'auditormanager']
    },
    {
        id: 3,
        label: 'Received',
        key:'received',
        divPrimary: 'primaryreceived',
        divsScondary: 'received',
        divsubSecondary: '#D9BAF4',
        typography: 'typoRobotoRegoularprimary2914',
        color: "#58508D",
        roles:['auditor', 'auditoradmin', 'auditormanager']
    },
    {
        id: 4,
        label: 'Not Received',
        key:'not received',
        divPrimary: 'primarynotreceived',
        divsScondary: 'notreceived',
        divsubSecondary: '#FFECEE',
        typography: 'typoRobotoRegoularprimary3114',
        color: "#F83026",
        roles:['auditor', 'auditoradmin', 'auditormanager']
    },
    {
        id: 5,
        label: 'Transcribed',
        key:'transcribed',
        divPrimary: 'primarytranscribed',
        divsScondary: 'transcribed',
        divsubSecondary: '#84B9FD',
        typography: 'typoRobotoRegoularprimary7114',
        color: "#84B9FD",
        roles:['auditor', 'auditoradmin', 'auditormanager']
    },
    {
        id: 6,
        label: 'Review',
        key:'review',
        divPrimary: 'primaryreview',
        divsScondary: 'review',
        divsubSecondary: '#FCD6BB',
        typography: 'typoRobotoRegoularprimary6512',
        color: "#FF6700",
        roles:['auditor']
    },
    {
        id: 7,
        label: 'Claim Submitted',
        key:'claim submitted',
        divPrimary: 'primaryclaimsubmitted',
        divsScondary: 'claimsubmitted',
        divsubSecondary: '#E9F5CE',
        typography: 'typoRobotoRegoularprimary3314',
        color: "#59A531",
        roles:['auditor', 'auditoradmin', 'auditormanager']
    },
]

export const options = [
    { id: 1, key: 0, label: 'View Task', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 2, key: 1, label: 'Edit Task', roles: ['auditoradmin', 'auditormanager'] },
    { id: 3, key: 2, label: 'Reassign Task', roles: ['auditoradmin', 'auditormanager'] },
    { id: 4, key: 3, label: 'Change Status', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 5, key: 6, label: 'Mark Claim Submitted', roles: ['auditoradmin', 'auditormanager'] },
]
export const settingsOptions = [
    { id: 1, key: 'taskName', readonly: false, fil:true, label: 'Task Name', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 2, key: 'orgTid', readonly: false, fil:true, label: 'Organization TIN', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 3, key: 'auditorName', readonly: false, fil:true, label: 'Auditor Name', roles: ['auditoradmin', 'auditormanager'] },
    { id: 4, key: 'location', readonly: false, fil:true, label: 'Location', roles: ['auditoradmin', 'auditormanager'] },
    { id: 5, key: 'providerName', readonly: false, fil:true, label: 'Provider Name', roles: ['auditoradmin', 'auditormanager','auditor'] },
    { id: 6, key: 'speciality', readonly: false, fil:true, label: 'Speciality', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 7, key: 'status', readonly: true, fil:true, label: 'Status', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 8, key: 'patientAccount', readonly: false, fil:true, label: 'Patient Account', roles: ['auditoradmin', 'auditormanager'] },
    { id: 9, key: 'audio', readonly: true, label: 'Audio',fil:false, roles: ['auditoradmin', 'auditormanager','auditor'] },
    { id: 10, key: 'encounterCollection', readonly: false, fil:false, label: 'Encounter Collection', roles: ['auditoradmin', 'auditormanager'] },
    { id: 11, key: 'comment', readonly: true, fil:false, label: 'Comment', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 12, key: 'startDate', readonly: false, fil:false, label: 'Start Date', roles: ['auditoradmin', 'auditormanager'] },
    { id: 13, key: 'endDate', readonly: false, fil:false, label: 'End Date', roles: ['auditoradmin', 'auditormanager'] },
    { id: 14, key: 'appointmentType', readonly: false, fil:true, label: 'Appointment Type', roles: ['auditoradmin', 'auditormanager'] },
    { id: 15, key: 'patientName', readonly: false, fil:true, label: 'Patient Name', roles: ['auditoradmin', 'auditormanager', 'auditor'] },
    { id: 16, key: 'transcript', readonly: false, label: 'Transcripts',fil:false, roles: ['auditoradmin', 'auditormanager','auditor'] },
    { id: 17, key: 'mrn', readonly: false, label: 'MRN',fil:false, roles: ['auditoradmin', 'auditormanager','auditor'] },
    { id: 18, key: "fillablePdf", readonly: true, label: 'Progress Notes',fil:false, roles: ['auditoradmin', 'auditormanager','auditor'] },
    { id: 19, key: "document3", readonly: false, label: 'Document 3',fil:false, roles: ['auditoradmin', 'auditormanager','auditor'] },
]
export const NotificationsData = [
    {
        id: 1,
        com: "Audio is added to the 'Task Reviewing policies and procedures'",
        date: 'August 23,2022',
        time: '9:25am',
        list: 'play audio',
    },
    {
        id: 2,
        com: "Task 'Reviewing policies and procedures' status changed from In Progress to To do",
        date: 'August 23,2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 3,
        com: "Sent you a message on Task 'Reviewing policies and procedures'",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view chat',
        name: 'Matt Wingate',
    },
    {
        id: 3,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 4,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 5,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 6,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 7,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
]

export function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xcf
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function stringToborColor(string: string) {
    let hash = 0
    let i
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    return color;
}

export const findLength = (strings: any) => {    
    const total = strings?.length;
    const names = strings?.map((item: any) => { return item.name })
    const vlength = names.toString().substring(0, 21);
    const tt = total - vlength.split(',').length;
    return tt > 0 ? `${names.join(', ').substring(0, 21)}...+${+total - vlength.split(',').length}` : `${names.join(', ').substring(0, 21)}...`;
}
export const findThreaLength = (strings: any) => {    
    const total = strings?.length;
    const names = strings?.map((item: any) => { return item.name })
    const vlength = names.toString().substring(0, 31);
    const tt = total - vlength.split(',').length;
    return tt > 0 ? `${names.join(', ').substring(0, 31)}...+${+total - vlength.split(',').length}` : `${names.join(', ').substring(0, 31)}...`;
}
export const roleName = (userRole: string) => {
    if(userRole === "auditormanager"){
        return "Auditor Manager";
    }else if(userRole === "provider"){
        return "Provider";
    }
    return  userRole === "auditor" ? "Auditor" :"Admin";
}
export const urlParse = (strings: string) => {
    const urlSplit = strings.split('/');
    const sliceurl = urlSplit.slice(3);
    return sliceurl.map((item, index) => (index === sliceurl.length -1) ? item: item+"/" ).join("")
}
    
export function getTrancriptFilePath(transcript:any) {
    switch (transcript.seleced) {
        case "A":
            return transcript.filePathPDF_2A;  
        case "B":
            return transcript.filePathPDF_2B;  
        case "C":
            return transcript.filePathPDF_2C;  
        case "D":
            return transcript.filePathPdf_ChartNote;  
        default:
            return transcript.filePathPdf_D3;
    }
}
export const singleFileErrMsg = 'Only one file to be upload.';
export const fileTypeErrMsg = 'File format not supported!';
export const fileSizeErrMsg = 'File size exceeds! Only upto 16MB allowed to upload.';
export const ImgfileTypeErrMsg = 'Invalid file type! Allowed file types ( jpeg, jpg, png )';
export const cancelErrMsg = 'Upload Cancelled';

export const Roleoptions = [
	'provider',
	'auditor',
	'auditormanager',
	'auditoradmin'
];