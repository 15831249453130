import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { LoaderProvider } from './Components/Loader/LoaderProvider'
import { SnackbarProvider as SnackProvider } from './Components/Snackbar/SnackbarProvider'
import { AlertProvider } from './Components/GlobalAlert/GlobalAlertProvider'
import { SocketContext } from './Components/Sockets/Socket';
import io from 'socket.io-client';
import { AxiosInterceptor } from "./axios";
import { Provider } from 'react-redux'
import rootReducer from '../src/Services/reducers'
import { SnackbarProvider } from 'notistack';
import ReportComplete from './Components/ReportComplete/ReportComplete';
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

const store = configureStore({ reducer: rootReducer });

const persistor = persistStore(store);


export const socket = io(`${process.env.REACT_APP_SOCKET}`, { secure: true, path: `${process.env.REACT_APP_SOCKETPATH}/socket.io` });
declare module "notistack" {
    interface VariantOverrides {
      reportComplete: true;
    }
  }
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            <SocketContext.Provider value={{ socket }}>
                <LoaderProvider>
                    <SnackbarProvider Components={{ reportComplete: ReportComplete}} >
                        <SnackProvider>
                            <AlertProvider>
                                <AxiosInterceptor>
                                    <App />
                                </AxiosInterceptor>
                            </AlertProvider>
                        </SnackProvider>
                    </SnackbarProvider>
                </LoaderProvider>
            </SocketContext.Provider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
