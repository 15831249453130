import React from 'react'
import { LoaderGFContext } from "../../Components/Loader/LoaderProvider";
import { SnackbarContext } from "../../Components/Snackbar/SnackbarProvider";
import { getProfile, uploadProfile } from '../api/userAPIService';
import { readFileFromURL } from '../FileService/fileread';
import { fileSizeErrMsg, ImgfileTypeErrMsg, singleFileErrMsg } from '../utils/constant';
import { userProfileAction } from '../actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
const allowedFileFormat = [
    "image/jpeg",
    "image/jpg",
    "image/png"
];
export function useEditProfile() {
    const { loaderSetting } = React.useContext(LoaderGFContext);
    const { snackbarSetting } = React.useContext(SnackbarContext);
    const dispatch = useDispatch();
    const [ profileDialogPic, setProfileDialogPic ] = React.useState<any>(null);
    const [ profilePic, setProfilePic ] = React.useState<any>(null);
    const userId = useSelector((state: any) => state.persistReducers?.userReducer?.user?.id);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);

    React.useEffect(() => {
        async function initaite() {
            if(userId){                
                await getUsetprofileData()
            }
        }
        initaite();
    },[userId]);
    const getUsetData = async () => {
        loaderSetting(true)
        const result = await getProfile({ userId: userId});
        if(result && result?.status && result?.user?.profile){
            const option = {
                method: 'GET',
                mode:'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }            
            const streamURL = await readFileFromURL(`profile/${result?.user?.profile.split('/')[result?.user?.profile.split('/')?.length - 1]}`,option);
            setProfileDialogPic(streamURL);
            loaderSetting(false)
        }else{
            setProfileDialogPic(null);
            loaderSetting(false)
        }
    }
    const getUsetprofileData = async () => {
        const result = await getProfile({ userId: userId});
        if(result && result?.status && result?.user?.profile){
            const option = {
                method: 'GET',
                mode:'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }
            const streamURL = await readFileFromURL(`profile/${result?.user?.profile.split('/')[result?.user?.profile.split('/')?.length - 1]}`,option);
            setProfilePic(streamURL);
        }else{
            setProfilePic(null);
        }
    }
    const getBase64 = (file: any) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
        });
    };
    const handleChange = async (event: any) => {
        loaderSetting(true)
        const formData = new FormData();
        const obj = {
            attachment: ''
        }
        if (event.target.files?.length > 1) {
            snackbarSetting(true, singleFileErrMsg, "error")
            loaderSetting(false)
        } else if (!allowedFileFormat.includes(event.target.files?.[0]?.["type"])) {
            snackbarSetting(true, ImgfileTypeErrMsg, "error")
            loaderSetting(false)
        } else if (event.target.files?.[0]?.["size"] > 16e6) {
            snackbarSetting(true, fileSizeErrMsg, "error")
            loaderSetting(false)
        }
        else {
            const base64_data: any = await getBase64(event.target.files[0]);
            const attachment = base64_data?.split(',')[1];
            obj.attachment = event.target.files[0];
            formData.append('picture', new Blob([attachment]));
            const msgText = event.target.files?.[0]?.['name'];
            formData.append('fileName', msgText);
        }
        if (obj.attachment) {
            formData.append('profile', "true");
            const result = await uploadProfile(formData);
            if (result?.status) {                  
                dispatch(userProfileAction(JSON.stringify(result?.resData?.profile)));
                if(result && result?.status && result?.resData?.profile){
                    const option = {
                        method: 'GET',
                        mode:'cors' as RequestMode,
                        headers: new Headers({
                            'Authorization': 'Bearer ' + userToken
                        })
                    }
                    const streamURL = await readFileFromURL(`profile/${result?.resData?.profile.split('/')[result?.resData?.profile.split('/')?.length - 1]}`,option);
                    setProfileDialogPic(streamURL);
                    getUsetprofileData();
                    loaderSetting(false)
                }
                loaderSetting(false)
                snackbarSetting(true, result?.message, "success")
            } else {
                loaderSetting(false);
                snackbarSetting(true, result?.message, "error")
            }
        }
    }
    const handleRemove = async () => {
        loaderSetting(true)
        const formData = new FormData()
        formData.append('profile', "false");
        loaderSetting(true)
        const result = await uploadProfile(formData);
        if (result) {
            dispatch(userProfileAction(JSON.stringify(result?.resData.profile)));
            loaderSetting(false)
            setProfileDialogPic(null);
            setProfilePic(null);
            snackbarSetting(true, result?.message, "success")
        } else {
            loaderSetting(false)
            snackbarSetting(true, result?.message, "error")
        }
    }
    return { handleRemove, handleChange, profileDialogPic, profilePic, getUsetData, setProfileDialogPic };

}