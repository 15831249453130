export const readFileFromURL = async (path: string, option: any) => {
    try {
        const result = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}task/streamFile/${path}`, option);
        const objURL = await result.blob();
        return URL.createObjectURL(objURL);
    } catch (error) {
        return error;
    }
}
export const readTextFromURL = async (path: string, option: any) => {
    try {
        const result = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}task/streamFile/${path}`, option);
        const objURL = await result.text();
        return objURL;
    } catch (error) {
        return error;
    }
}
export const readTranscriptFileFromURL = async (option: any, path: string) => {
    try {
        const result = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}encounterTranscript/${path}`,option);
        const objURL = await result.blob();
        return URL.createObjectURL(objURL);
    } catch (error) {
        return error;
    }
}
export const readTranscriptFileFromURLToFile = async (option: any, path: string, mainFileName: string) => {
    try {
        const result = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}encounterTranscript/${path}`,option);
        const objURL = await result.blob();
        const file = new File([objURL], mainFileName,{type:"pdf"});        
        return file;
    } catch (error) {
        return error;
    }
}
function progress(val: any, total: any, setDownProgress: any) {
    setDownProgress(Math.round(val / total * 100));
}
export const readFromURL = async (path: string, option: any, setDownProgress?: any) => {
    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}task/streamFile/${path}`, option)
        .then((response: any) => {
            const reader = response.body.getReader();
            const contentLength = response.headers.get('content-length');
            const total = parseInt(contentLength, 10);
            let loaded = 0;
            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }: any) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            loaded += value.byteLength;
                            progress(loaded, total, setDownProgress)
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                },
            });
        })
        .then((stream) => new Response(stream))
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => { return url })
        .catch((err) => console.error(err));
}