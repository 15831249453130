import { createContext, FC, ReactNode, useCallback, useMemo, useState } from "react";
import GlobalAlret from "./GlobalAlret";
import { GlobalAlertContext } from './interface'
export const GlobalAlertGFContext = createContext<GlobalAlertContext>({} as GlobalAlertContext);

export const AlertProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState<any>(null);

  const alertSetting = useCallback((data: boolean, lab: number| null) => {    
    setOpen(data)
    setLabel(lab)
  },[setOpen])

  const value = useMemo(() => ({ open, alertSetting }), []);

  const handleClose = () => {
    setOpen(false)
    setLabel(null)
  }
  return (
    <GlobalAlertGFContext.Provider value={value}>
      {children}
      <GlobalAlret open={open} label={label} handleClose={handleClose}/>
    </GlobalAlertGFContext.Provider>
  );
};
