import React from "react";
import { getNotification, getNotificationCount, markAllasRead, markasRead } from '../api/notificationAPIService';
import { LoaderGFContext } from "../../Components/Loader/LoaderProvider";
import { notificationReducer } from "../reducer/notificationReducer";
import { SnackbarContext } from "../../Components/Snackbar/SnackbarProvider";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../Components/Sockets/Socket";
import { notificationCountSetting } from "../actions/notificattionAction";

export function useNotification() {
    const [notificationData, setNotificationData] = React.useReducer(notificationReducer, []);
    const [pagination, setPagination] = React.useState({pageSize: 10,pageNumber: 1});
    const listRootRef = React.useRef(null);
    const { loaderSetting } = React.useContext(LoaderGFContext);
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const userId = useSelector((state: any) => state.persistReducers?.userReducer?.user?.id);

    const dispatch = useDispatch();
    const { socket } = React.useContext(SocketContext);

    const onScroll = () => {
        if (listRootRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRootRef.current;
            if ((scrollTop) + clientHeight === scrollHeight) {
                setPagination({ ...pagination, pageSize: pagination.pageSize, pageNumber: pagination.pageNumber + 1 })
            }
        }
    };

    const markAllasReadFun = async () => {
        loaderSetting(true)
        const result: any = await markAllasRead();
        if(result?.status){
            snackbarSetting(true, result?.message,  "success")
            const bodyParams = {
                pageSize: pagination.pageSize,
                pageNumber: 1,
            };
            const notifications: any = await getNotification(bodyParams); 
            if(notifications && notifications?.payload?.length > 0){
                setNotificationData({ type: "addnew", payload: notifications?.payload });
                dispatch(notificationCountSetting(0))
            }
        }else{
            snackbarSetting(true, result?.message,  "error")
        }
        loaderSetting(false)
    }

    const markasReadFun = async (item: any) => {
        if(!item.readby?.includes(userId)){
            loaderSetting(true)
            const result: any = await markasRead({id: item.id});
            if(result?.status){                
                snackbarSetting(true, result?.message,  "success")
                setNotificationData({ type: "update", payload: result?.payload });
                dispatch(notificationCountSetting(result?.count))
            }else{
                snackbarSetting(true, result?.message,  "error")
            }
            loaderSetting(false)
        }
    }
    React.useEffect(() => {
        async function initiate() {
            loaderSetting(true)
            const bodyParams = {
                pageSize: pagination.pageSize,
                pageNumber: pagination.pageNumber,
            };
            const notifications: any = await getNotification(bodyParams);            
            if(notifications && notifications?.payload?.length > 0){
                setNotificationData({ type: "add", payload: notifications?.payload });
                getNotificationCountFun()
            }
            loaderSetting(false)
        }
        initiate();
    }, [pagination.pageNumber]);
    const receiverSocket = async (data: any) => {
        const message: any = data.message;         
        if(message.createBy !== userId){
            setNotificationData({ type: "addNew", payload: message });
        }
    }
    React.useEffect(() => {
        socket?.on('notification', receiverSocket);
    }, [socket]);
    const getNotificationCountFun = async () => {
        const result: any = await getNotificationCount();
        if(result?.status){
            dispatch(notificationCountSetting(result?.payload))
        }
    }
    return { notificationData, onScroll, listRootRef, markAllasReadFun, markasReadFun };
}