import { FILE_PREVIEW } from "../../actions/actionType";

interface AuthState {
  filePreview: boolean;
}

interface Action {
  type: string;
  payload: any;
}

const initialState: AuthState = {
  filePreview: false,
};


export default function authMonitorReducer(state: AuthState = initialState, action: Action) {
  
  switch (action.type) {
    case FILE_PREVIEW: {
      return {
        ...state,
        filePreview: action.payload,
      };
    }
    default:
      return state;
  }
}
