import { NOTIFICATION_COUNT } from "./actionType"

const notificationCountSetting = (data: any) => {
    return {
        type: NOTIFICATION_COUNT,
        payload: data
    }
}
export {
    notificationCountSetting
}