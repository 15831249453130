import * as React from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import AdbIcon from '@mui/icons-material/Adb'
import typoStyles from '../../Styles/component/Typography.module.css'
import Notification from '../../Components/Notification/Notification';
import NotificationDrawer from '../../Components/NotificationDrawer'
import Logo from '../../Assests/Icons/MicrosoftTeams-image (4).png';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { roleName } from '../../Services/utils/constant'
import { useSelector } from 'react-redux'

interface HeaderProps {
    notificationCount?: any
}
const Header = ({ notificationCount }: HeaderProps) => {
    const userRole = useSelector((state: any) => state.persistReducers?.userReducer?.user?.userRole);

    const [, setAnchorElUser] = React.useState<null | HTMLElement>(null)
    const [openDrawer, setopenDrawer] = React.useState(false)

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setopenDrawer(true)
        setAnchorElUser(event.currentTarget)
    }


    const closedrawer = () => {
        setopenDrawer(false)
        setAnchorElUser(null)
    }
    return (
        <Container
            maxWidth={false}
            sx={{
                paddingLeft: 0,
                marginLeft: 0,
            }}
        >
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                <Toolbar style={{ margin: 0, padding: 15 }}>
                    <img src={Logo} alt='Logo' width={"42px"} height={"29px"} />
                    <Typography
                        variant="h6"
                        noWrap
                        className={typoStyles.typoRobotoBoldprimary1214}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex', marginLeft: "12px" },
                        }}
                    >
                        {userRole && roleName(userRole)}
                    </Typography>
                    <AdbIcon
                        sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
                    />
                    <Typography
                        variant="h5"
                        noWrap
                        className={typoStyles.typoRobotoBoldprimary1214}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                        }}
                    >
                        {userRole && roleName(userRole)}
                    </Typography>
                </Toolbar>
                <Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open Notification">
                            <Badge badgeContent={notificationCount} color="error">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}
                                >
                                    <NotificationsNoneIcon />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Box>
                </Box>
            </Toolbar>
            {openDrawer && (
                <NotificationDrawer openDrawer={true}>
                    <Notification closedrawer={closedrawer} />
                </NotificationDrawer>
            )}
        </Container>
    )
}
export default Header
