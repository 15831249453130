import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Success }  from '../../../Assests/Icons/success.svg';
import { IconButton } from "@mui/material";
import styles from './SnackbarComponent.module.css';
import { ReactComponent as Error }  from '../../../Assests/Icons/Icons svg/Cross error.svg';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}
interface ISnackbarProps {
  open: boolean,
  message: string,
  type: string,
  handleClose:() => void
}
export default function SnackbarComponent({ open, message, type, handleClose }: ISnackbarProps) {

  if(type === "success"){
    return (
      <div>
        <Snackbar
          sx={{zIndex: 2000}}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          autoHideDuration={2000}
          open={open}
          TransitionComponent={SlideTransition}
          message={message}
        >
          <div className={styles.snackdiv}>
          <div className={styles.linediv}></div>
          <div className={styles.SnackbarComponent}>
            <Success style={{width:"27px", height:"27px"}}/>
            <span >{message ? message :"Something went wrong"}</span>
            <IconButton onClick={()=>{
              if(handleClose){
                handleClose()
              }
            }} size='small'>
              <CloseIcon />
            </IconButton>
          </div>
          </div>
        </Snackbar>
      </div>
    );
  }else if(type === "error"){
    return (
      <div>
        <Snackbar
        sx={{zIndex: 2000}}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          autoHideDuration={2000}
          open={open}
          TransitionComponent={SlideTransition}
          message={message}
        >
          <div className={styles.snackdiv}>
          <div className={styles.Errorlinediv}></div>
          <div className={styles.ErrorSnackbarComponent}>
            <Error style={{width:"27px", height:"27px"}}/>
            <span >{message ? message :"Something went wrong"}</span>
            <IconButton onClick={()=>{
              if(handleClose){
                handleClose()
              }
            }} size='small'>
              <CloseIcon />
            </IconButton>
          </div>
          </div>
        </Snackbar>
      </div>
    );
  }else{
    return null;
  }
}
