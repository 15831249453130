import _ from 'lodash';

export const notificationReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'addnew':
            return action.payload;
        case 'add':
            return _.unionBy([...state, ...action.payload],'id');
        case 'update': {
            const productIndex = state.findIndex((item: any) => item.id === action?.payload?.id);
            if(productIndex < 0) {
                return state;
            }
            const update = [...state];            
            update.splice(productIndex, 1, action.payload)           
            return _.unionBy(update);
        }
        case 'addNew':
            return _.unionBy([action.payload, ...state,],'id');
        case 'removeall':      
            return action.payload;
        default:
            return state;
    }
}
