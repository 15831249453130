import React, { useMemo } from "react"
import { readFromURL } from "../FileService/fileread";
import { audioReducer } from "../reducer/audioReducer";
import { getEncounters } from "../api/audioAPIService";
import { useDispatch, useSelector } from "react-redux";
import { file_preview_open } from '../../Services/actions/authMonitorAction'
import { audioModal_action, audioPlayer_action } from "../actions/audioPlayerAction";
export function useAudiopopupService() {
    const appointmentData = useSelector((state: any) => state.nonPersistReducers.audioPlayerReducer.appointmentData);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);

    const listRootref = React.useRef(null);
    const [files, setFiles] = React.useReducer(audioReducer, []);
    const [selectedFile, setSelectedFile] = React.useState<any>(null);
    const [blobdata, setBlob] = React.useState<any>(null)
    const [loader, setLoader] = React.useState(false);
    const [downProgress, setDownProgress] = React.useState(0);
    const [pagination, setPagination] = React.useState({
        pageSize: 10,
        pageNumber: 1
    });
    const dispatch = useDispatch();
    const audioRef = React.useRef<HTMLAudioElement>(null);
    const [ isPlaying, setIsPlaying ] = React.useState(false);
    const [ songVlu, setSongvlu ] = React.useState<number>(0);
    const [songInfo, setSongInfo] = React.useState({
            currentTime: 0,
            duration: 0,
            animationPercentage: 0,
    });

    const fileurl = useMemo(() =>{ return blobdata }, [blobdata]);

    const onScroll = () => {
        if (listRootref.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRootref.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setPagination({ ...pagination, pageSize: pagination.pageSize, pageNumber: pagination.pageNumber + 1 })
            }
        }
    };

    React.useEffect(() => {
        async function initiate() {
            const bodyParams = {
                pageSize: pagination.pageSize,
                pageNumber: pagination.pageNumber,
                appointmentId: appointmentData?.appointmentId,
                scheduleId: appointmentData?.scheduleId,
                encounterId: appointmentData?.encounterId
            };
            const encounters: any = await getEncounters(bodyParams);
            if (encounters?.status) {
                setFiles({ type: "add", payload: encounters?.result });
                if(encounters?.currentPage === 1) {
                    setSelectedFile(encounters?.result[0]);
                    selectSong(encounters?.result[0])
                }
            }
        }
        initiate();
    }, [pagination.pageNumber]);

    const handleClose = () => {
        dispatch(audioModal_action(false))
        dispatch(audioPlayer_action(null))
        dispatch(file_preview_open(false))
    }

    const selectSong = async (audioFile: any) => {
        setSelectedFile(audioFile);
        setDownProgress(0)
        setLoader(false);
        const option = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + userToken
            })
        }            
        const result = await readFromURL(audioFile.filePathKey, option, setDownProgress);        
        if (result) setBlob(result);
    }
   
    React.useEffect(() => {
        async function initiate() {
            if(loader){
                audioRef?.current?.pause();
                setIsPlaying(false);
            }else{
                audioRef?.current?.play();
                setIsPlaying(true);
            }
        }
        initiate()
    },[blobdata]);
    React.useEffect(() => {
        if (audioRef?.current) {
            audioRef?.current?.play();
            setIsPlaying(true);
        }
    }, [audioRef?.current]);

    const playSongHandler = () => {
        if(!audioRef?.current) return;
        isPlaying ? audioRef.current.pause() : audioRef.current.play()
        setIsPlaying(!isPlaying)
    }
    const getTime = (seconds: any) => {
        const val = Math.round(seconds);
        return [3600, 60].reduceRight((p: any, b:any) => (r: any) => [Math.floor(r / b)].concat(p(r % b)),r => [r])(val).map((a: any) => a.toString().padStart(2, '0')).join(':');
    }
    const dragHandler = (e: any) => {
        if(audioRef?.current) {
            audioRef.current.currentTime = e.target.value
            setSongInfo({ ...songInfo, currentTime: e.target.value });
            setSongvlu(e.target.value)
        }
    }
    const fastForward = () => {
        if(!audioRef?.current) return;
        if (!isPlaying) {
            audioRef.current.play();
            setIsPlaying(!isPlaying)
            audioRef.current.currentTime += 10
        } else {
            audioRef.current.currentTime += 10
        }
    }
    const fastBackward = () => {
        if(!audioRef?.current) return;
        audioRef.current.currentTime -= 10
    }
    const FastBackwardPlay = () => {
        if(!audioRef?.current) return;
        audioRef.current.currentTime -= 20
    }
    const FastForwardPlay = () => {
        if(!audioRef?.current) return;
        audioRef.current.currentTime += 20
    }
    const timeUpdateHandler = (e: any) => {
        const current = e.target.currentTime
        const duration = e.target.duration
        const roundedCurrent = Math.round(current)
        const roundedDuration = Math.round(duration)
        const animation = Math.round((roundedCurrent / roundedDuration) * 100)
        setSongInfo({
            ...songInfo,
            currentTime: current,
            duration,
            animationPercentage: animation,
        })
    }
    React.useEffect(() => {
        const handleEnd = () => {
            setIsPlaying(false);
            setSongInfo({ ...songInfo, currentTime: 0 });
            setSongvlu(0);
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('ended', handleEnd);
        }

        if (songInfo) {
            setSongvlu(songInfo.currentTime);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleEnd);
            }
        };
    }, [audioRef, setSongInfo, songInfo, setIsPlaying, setSongvlu]);
    return { FastForwardPlay, FastBackwardPlay, songVlu, dragHandler, getTime, fastBackward, playSongHandler, fastForward, timeUpdateHandler, audioRef, songInfo, isPlaying, fileurl, files, selectedFile, selectSong, handleClose, blobdata, loader, setLoader, downProgress, listRootref, onScroll };
}