import * as React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { LogoutService } from '../../Services/api/userAPIService';
import buttleStyles from '../../Styles/component/ButtonStyle.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';
import { useNavigate } from 'react-router-dom'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider'
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider'
import { SocketContext } from '../../Components/Sockets/Socket'

type logoutAlertProps = {
    open: boolean
    handleClose: () => void;
}
export default function LogoutDialog(props: logoutAlertProps) {

    const navigate = useNavigate()
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { socket } = React.useContext(SocketContext)
    const handleLogout = async () => {
        loaderSetting(true)
        await LogoutService().then((res: any) => {
                if (res?.status && res?.message) {
                    loaderSetting(false);
                    snackbarSetting(true, res?.message,"success")
                    localStorage.clear();
                    sessionStorage.clear();
                    props.handleClose()
                    socket.disconnect()
                    navigate('/')
                }else{
                    loaderSetting(false)
                    snackbarSetting(true, res?.message,"error")
                }
        }).catch((err) => {
            console.log(err);
            loaderSetting(false)
        })
    }
    return (
        <Dialog style={{zIndex:1200}} open={props.open} maxWidth={"xs"} fullWidth onClose={props.handleClose}>
            <DialogTitle variant='h6'>Logout</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to Logout?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{
                marginRight:"20px"
            }}>
                <Button
                    className={buttleStyles.cancelbutton}
                    variant="contained"
                    onClick={() => {
                        props.handleClose()
                    }}
                >
                    <Typography className={typoStyles.typoRobotoRegoularprimary5914}>
                        {'No'}
                    </Typography>
                </Button>
                <Button className={buttleStyles.yesbutton} variant="contained" onClick={handleLogout}>
                    Yes
                </Button>
            </DialogActions>
            <div style={{height:"15px"}}></div>
        </Dialog>
    )
}
