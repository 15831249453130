import _ from 'lodash';

export const audioReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'add':
            return _.unionBy([...state,...action.payload],'id');
        case 'removeall':      
            return action.payload;
        default:
            return state;
    }
}
