import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import styles from './ProfileDialog.module.css';
import { Button, Tooltip } from '@mui/material';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import { ReactComponent as UserIcon } from '../../../../Assests/Icons/UserIcon.svg'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import imagePlaceHolder from '../../../../Assests/Icons/Icons svg/Imagethumbnail.png';
import { useSelector } from 'react-redux';

export interface ProfileDialogProps {
  open: boolean
  onClose: React.MouseEventHandler<HTMLButtonElement>,
  handleRemove: any,
  handleChange: any,
  profilePic: any
}

export default function ProfileDialog(props: ProfileDialogProps) {
  const { onClose, open, handleRemove, handleChange, profilePic } = props
  const user = useSelector((state: any) => state.persistReducers?.userReducer?.user);
  const loadimage = () => {
    URL.revokeObjectURL(profilePic);
  }
  const onErrorImage = (e: any) => {
    e.target.src = imagePlaceHolder;
  }
  return (
    <Dialog
      onClose={onClose}
      open={open}
      style={{ zIndex: 1200 }}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'inherit',
        }
      }}

    >
      <div className={styles.dialogPaper}>
      <Tooltip title="Close Profile">
        <IconButton onClick={onClose} className={styles.icondiv}>
          <CloseIcon />
        </IconButton>
        </Tooltip>
        <div className={styles.ProfileDialog}>
          {profilePic === null ? <div className={styles.defprofileDiv}>
            <UserIcon style={{ width: "29px", height: "37px" }} />
          </div> :
            <div className={styles.profileDiv}>
              <img onError={(e) => onErrorImage(e)} onLoad={() => loadimage()} draggable={false} crossOrigin='anonymous' src={profilePic} alt='img221' style={{ width: "100%", height: "100%", borderRadius: "5px" }} />
            </div>}
          <span style={{ paddingTop: "20px" }} className={typoStyles.typoRobotoBoldprimary718}>{user?.firstName+" "+user?.lastName}</span>
          <span style={{ paddingTop: "8px" }} className={typoStyles.typoRobotoRegoular14}>{user?.email}</span>
          {profilePic === null ?
            <div>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={(event) => handleChange(event)}
              />
              <label htmlFor="contained-button-file">
                <Button className={styles.addprofileBtn} component="span">
                  <span className={typoStyles.typoRobotoRegoularprimary214}>{"Add Profile Picture"}</span>
                </Button>
              </label>
            </div> :
            <div className={styles.twobtns} style={{ display: 'flex', justifyContent: 'space-evenly', gap: '16px' }}>
              <Button className={styles.removeimagetn} component="span" onClick={handleRemove}>
                <span className={typoStyles.typoRobotoRegoularprimary2114}>{"Remove Image"}</span>
              </Button>
              <div>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(event) => handleChange(event)}
                />
                <label htmlFor="contained-button-file">
                  <Button className={styles.changeimagetn} component="span">
                    <span className={typoStyles.typoRobotoRegoularprimary214}>{"Change Image"}</span>
                  </Button>
                </label>

              </div>
            </div>
          }
        </div>
      </div>
    </Dialog>
  )
}
