import { NOTIFICATION_COUNT } from "../../actions/actionType"

const initialState = {
    count: 0,
}

export default function notificationCountReducer(state = initialState, action: any) {
    switch (action.type) {
        case NOTIFICATION_COUNT: {
            return {
                ...state,
                count: action.payload
            }
        }
        default:
            return state
    }
}