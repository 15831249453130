import { createContext, FC, ReactNode, useCallback, useMemo, useState } from "react";
import GlobalLoader from "./GlobalLoader";
import { LoaderContext } from './interface'
export const LoaderGFContext = createContext<LoaderContext>({} as LoaderContext);

export const LoaderProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [loader, setLoader] = useState(false);

  const loaderSetting = useCallback((data: boolean) => {
      setLoader(data)
  },[setLoader])

  const value = useMemo(() => ({ loader, loaderSetting  }), []);
  return (
    <LoaderGFContext.Provider value={value}>
      {children}
      <GlobalLoader open={loader}/>
    </LoaderGFContext.Provider>
  );
};
