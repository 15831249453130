export const FILE_PREVIEW = 'FILE_PREVIEW';
export const APPOINTMENT_DATA = 'APPOINTMENT_DATA';
export const AUDIO_MODAL = 'AUDIO_MODAL';
export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT';
export const PROVIDER = 'PROVIDER';
export const SCHEDULEID = 'SCHEDULEID';
export const WORKLOADDATE = 'WORKLOADDATE';
export const USER = 'USER';
export const USERSETTING = 'USERSETTING';
export const USERTOKEN = 'USERTOKEN';
export const USERRETOKEN = 'USERRETOKEN';
export const PROFILE = 'PROFILE';
