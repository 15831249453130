import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styles from './ReportComplete.module.css';
import { ReactComponent as Group17624 } from '../../Assests/Icons/Group 17624.svg';

interface ReportCompleteProps extends CustomContentProps {
  allowDownload?: boolean;
}

const ReportComplete = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} >
        <Card  className={styles.root}>
          <CardActions className={styles.toolbar}>
          <div >
            <Group17624 />
            <span style={{color:"#FFFFFF"}}>
              {props.message}
            </span>
           </div>
              <IconButton
                size="small"
                className={styles.expand}
                onClick={handleDismiss}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

ReportComplete.displayName = "ReportComplete";

export default ReportComplete;
