import API from './index';
import axios from "../../axios";

export const uploadProfile = (data: any) => API.handleApiRequest(() => API.formPost('auditor/uploadPic', data));

export const getProfile = (data: any) => API.handleApiRequest(() => API.post('auditor/getUser', data));

export const sessionLogout = (data: any) => API.handleApiRequest(() => API.post('auditor/session-logout', data));

export const getUsers = (data: any) => API.handleApiRequest(() => API.post('directChat/usersList', data));

export const getSpeciality = () => API.handleApiRequest(() => API.get('speciality'));

export const getProviders = (data: any) => API.handleApiRequest(() => API.post('speciality/providers', data));

export const createUser = (data: any) => API.handleApiRequest(() => API.post('auditor/userRegister', data));

export const loginService = (data: any) => API.handleApiRequest(() => API.post(`auditor/login`, data));

export const ResetService = (data: any) => API.handleApiRequest(() => API.post(`auditor/sendOtp`, data));

export const VerifyOtpService = (data: any) => API.handleApiRequest(() => API.post(`auditor/verifyOtp`, data));

export const ResetPasswordService = (data: any) => API.handleApiRequest(() => API.post(`auditor/reset`, data));

export const LogoutService = () => API.handleApiRequest(() => axios.post(`auditor/logout`));
