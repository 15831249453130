import { combineReducers } from 'redux'

import nonPersistReducers from './nonPersistReducers';
import persistReducers from './persistReducers';

const rootReducer = combineReducers({
    persistReducers,
    nonPersistReducers,
})

export default rootReducer;