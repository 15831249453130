import { APPOINTMENT_DATA, AUDIO_MODAL } from "../../actions/actionType"

const initialState = {
    appointmentData: null,
    audioModalOpen: false
}

export default function audioReducer(state = initialState, action: any) {
    switch (action.type) {
        case APPOINTMENT_DATA: {
            return {
                ...state,
                appointmentData: action.payload
            }
        }
        case AUDIO_MODAL: {
            return {
                ...state,
                audioModalOpen: action.payload
            }
        }
        default:
            return state
    }
}