import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import { Divider } from '@mui/material'

const drawerWidth = 440

type NotificationDrawerprops = {
    openDrawer: boolean
    children: React.ReactNode
}

export default function NotificationDrawer({
    openDrawer,
    children,
}: NotificationDrawerprops) {
    return (
        <Drawer
            open={openDrawer}
            sx={{
                width: drawerWidth,

                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 462,
                    boxSizing: 'border-box',
                    boxShadow: '-4px 1px 8px #0000000F',
                    marginTop: '80px',
                    backgroundColor: '#F7F8F9',
                },
            }}
            variant="persistent"
            anchor="right"
        >
            <Divider />
            {children}
        </Drawer>
    )
}
