import axios from 'axios';
import React, { useEffect } from "react";
import { GlobalAlertGFContext } from './Components/GlobalAlert/GlobalAlertProvider'
import { refreshToken } from './Services/api/authMonitor';
import { userAction, userProfileAction, userReTokenAction, userSettingAction, userTokenAction } from './Services/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`
});

const AxiosInterceptor = ({ children }: any) => {
    const { alertSetting } = React.useContext(GlobalAlertGFContext)
    const [refreshFlag, setRefreshFlag] = React.useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.persistReducers?.userReducer?.user);
    const reToken = useSelector((state: any) => state.persistReducers?.userReducer?.refreshToken);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);

    useEffect(() => {

        const resInterceptor = async (response: any) => {            
            if(response) return response.data;
            return null;
        };

        const errInterceptor = async (error: any) => {
            if (error?.response?.status === 440 || error?.response?.status === 413 || (error?.response?.status === 401 && userToken ) || error?.response?.status === 405) {
                alertSetting(true, 2)
            }
            if (error?.response?.status === 441 && !refreshFlag) {
                await refreshToken({ userName: user?.userName, token: reToken, userId: user?.id, role: user?.userRole }).then((res: any) => {
                    setRefreshFlag(false)
                    if (res.status) {
                        dispatch(userAction(res?.user));
                        dispatch(userSettingAction(res?.userSetting));
                        dispatch(userTokenAction(res?.userToken));
                        dispatch(userReTokenAction(res?.refreshToken));
                        dispatch(userProfileAction(JSON.stringify(res?.user.profile)));
                        instance.defaults.headers.common['Authorization'] = `Bearer ${res?.userToken}`;
                    }
                }).catch((err: any) => {
                    console.log(err);
                    setRefreshFlag(true)
                });
            }
            return Promise.reject(error?.response?.data);
        };

        const interceptor = instance.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );

        return () => instance.interceptors.response.eject(interceptor);
    }, []);

    return children;
};

export default instance;
export { AxiosInterceptor };
