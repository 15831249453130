import { InputBase, Typography } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { statusLabel, statusTagMenuItem } from '../../Services/utils/constant'
import statuslabel from '../../Styles/component/StatusLabel.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '205px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DEDEDE',
    borderRadius: '5px',
    [theme.breakpoints.up('sm')]: {
        //marginLeft: theme.spacing(1),
        width: 'auto',
    },
}))

export const SearchUser = styled('div')(({ theme }) => ({
    position: 'relative',
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EAEAEA',
    borderRadius: '26px',
    width: "100%"
}))
export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    font: 'normal normal 14px/19px !important',
    fontFamily: 'RobotoRegular, sans-serif !important',
    letterSpacing: '0px',
    color: '#939393 !important',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '205px',
        font: 'normal normal 14px/19px !important',
        fontFamily: 'RobotoRegular, sans-serif !important',
        color: '#000000 !important',
        [theme.breakpoints.up('sm')]: {
            width: '205px',
            // '&:focus': {
            //     width: '20ch',
            // },
        },
    },
}))
export const StatusTypography = styled(Typography)({
    padding: '3px 8px 3px 8px',
})
export const HeaderDiv = styled('div')({
    height: '63px',
    minHeight: '63px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
})
type StatusTagProps = {
    status: string,
    tag?: any
}
export const StatusTag = ({ status, tag }: StatusTagProps) => {
    const data = statusTagMenuItem?.find((item) => item.key === status);
    if (tag) {
        return (
            <span style={{fontWeight:"bold"}} className={typoStyles[`${data?.typography}`]}>
                {statusLabel(status)}
            </span>
        )
    } else {
        return (
            <div className={statuslabel[`${data?.divPrimary}`]}>
                <StatusTypography className={typoStyles[`${data?.typography}`]}>
                    {statusLabel(status)}
                </StatusTypography>
            </div>
        )
    }
}
