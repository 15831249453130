import styles from './AudioList.module.css';
import typoStyles from '../../../../../Styles/component/Typography.module.css'
import { Divider, List } from '@mui/material';
import moment from 'moment';
interface Props {
    songs: any
    selectSong: any,
    selectedFile: any,
    listRootref: any, 
    onScroll: any
}

const AudioList = (props: Props) => {
    const {
        songs,
        selectSong,
        selectedFile,
        listRootref,
        onScroll
    } = props;

    const itemOnclick = (song: any) => {
        if (song?.id !== selectedFile?.id) {
            selectSong(song)
        }
    }

    return (
        <div className={styles.audioListRoot}>
            <div className={styles.headerAudio}>
                <span className={typoStyles.typoRobotoBoldprimary21419}>
                    {"All Recording"}
                </span>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.listroot} ref={listRootref} onScroll={onScroll}>
                {songs?.map((song: any) => (<div key={song.id} >
                    <List
                        onClick={() => itemOnclick(song)}
                        className={song?.id === selectedFile?.id ? styles.active : styles.unactive}
                    >
                        <div>
                            <span
                                className={typoStyles.typoRobotoMediumprimary216}>
                                {song?.filePathKey?.split('/') ? song?.filePathKey?.split('/')[song?.filePathKey?.split('/').length - 1] : ''}
                            </span>
                            <div className={styles.datetime}>
                                <span className={typoStyles.typoRobotoRegoularprimary21024}>
                                    {moment(song.createdAt).format('DD/MMM/yyyy')}
                                </span>
                            </div>
                        </div>
                    </List>
                    <Divider className={styles.secondarydivider} />
                </div>))}
            </div>
        </div>
    )
}

export default AudioList;
