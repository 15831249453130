import { PROFILE, USER, USERRETOKEN, USERSETTING, USERTOKEN } from "../../actions/actionType"

const initialState = {
    user: null,
    userSetting: null,
    refreshToken: null,
    userToken: null,
    profile: null
}

export default function userReducer(state = initialState, action: any) {
    switch (action.type) {
        case USER: {
            return {
                ...state,
                user: action.payload
            }
        }
        case USERSETTING: {
            return {
                ...state,
                userSetting: action.payload
            }
        }
        case USERTOKEN: {
            return {
                ...state,
                userToken: action.payload
            }
        }
        case USERRETOKEN: {
            return {
                ...state,
                refreshToken: action.payload
            }
        }
        case PROFILE: {
            return {
                ...state,
                profile: action.payload
            }
        }
        default:
            return state
    }
}